.footer-container {
  width: 100%;
  background-color: black;
  min-height: 120px;
  padding-top: 10px;

  .footer-content {
    display: flex;
    flex-direction: row;

    .left-content {
      text-align: center;
      padding-left: 130px;
      img {
        max-width: 180px;
      }
      p {
        position: absolute;
        margin-top: -10px;
        color: white;
        font-family: "Barlow", sans-serif;
        font-weight: 200;
        font-size: 12.5px;
      }
      @media screen and (max-width: 1300px) {
        display: none;
      }
    }

    .middle-content {
      position: absolute;
      width: 100%;
      text-align: center;
      padding-top: 25px;
      p {
        margin-top: 10px;
        color: white;
        font-family: "Barlow", sans-serif;
        font-weight: 200;
      }
      a {
        font-size: 19px;
        color: white;
        font-weight: 300;
      }
    }

    .right-content {
      position: absolute;
      left: 88%;
      margin-top: 50px;
      @media screen and (max-width: 1300px) {
        display: none;
      }
    }
  }
}
