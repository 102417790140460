.carousel-container {
  background: radial-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0));
  padding-top: 150px;
  padding-bottom: 70px;
  h1 {
    font-family: "Barlow", sans-serif;
    font-size: 80px;
    color: white;
    text-align: center;
    @media screen and (max-width: 1300px) {
      font-size: 40px;
      padding-inline: 10px;
    }
  }
  h2 {
    font-size: 30px;
    color: white;
    text-align: center;
    font-weight: 300;
    @media screen and (max-width: 600px) {
      font-size: 22px;
    }
  }

  .skills-container {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(2, 2fr);
    gap: 55px;
    padding: 60px;
    @media screen and (max-width: 1000px) {
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
      gap: 40px;
      padding: 30px;
    }
    h3 {
      font-size: 1.6vw;
      font-weight: 500;
      color: white;
      text-align: center;
      text-transform: uppercase;
      @media screen and (max-width: 600px) {
        font-size: 26px;
      }
    }

    p {
      margin-top: 30px;
      font-size: 20px;
      font-weight: 300;
      color: white;
      text-align: center;
    }
  }

  .skills__item {
    min-height: 300px;
    display: grid;
    justify-content: center;
    align-content: center;
    grid-template-columns: repeat(2, 2fr);
    gap: 40px;
    background: linear-gradient(rgba(0, 0, 0, 0.745), rgba(0, 0, 0, 0.527));
    padding: 1.3rem;
    border-radius: 0px 20px 0px 20px;
    border: 2px solid transparent;
    border-color: rgba(243, 243, 243, 0.461);
    transition: all 0.2s ease-in-out;

    .skills__item-text {
      width: 100%;
      padding-block: 10px;
      text-align: center;
    }

    @media screen and (max-width: 1650px) {
      grid-template-columns: repeat(1, 1fr);
      width: 100%;
      gap: 40px;
      .skills__item-text {
        text-align: center;
        padding-inline: 10px;
      }
      padding-top: 15px;
    }

    img {
      max-width: 100%;
      border-radius: 0px 20px 0px 20px;
    }

    &:hover {
      background: rgb(0, 0, 0);
      box-shadow: 10px 10px rgba(213, 213, 213, 0.833);
      border-color: rgb(255, 255, 255);
      transition: all 0.2s ease-in-out;
      transform: scale(105%);
      li {
        color: rgb(255, 255, 255);
      }
    }
  }
}

.item {
  text-align: center;
  margin-top: 40px;
  padding: 30px;
  position: sticky;
  transition: transform 222ms ease-in-out;
  &:hover {
    transform: scale(120%);
  }
}
