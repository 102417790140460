.AboutInfo-container {
  padding-top: 8.5rem;
  overflow: hidden;
  @media screen and (max-width: 1300px) {
    padding-top: 7rem;
  }

  h1 {
    font-family: "Barlow", sans-serif;
    color: rgb(255, 255, 255);
    font-size: 70px;
    margin-bottom: 3rem;
    width: 100%;
    text-align: center;
    @media screen and (max-width: 1300px) {
      font-size: 40px;
      margin-bottom: 10px;
    }
  }

  h2 {
    font-family: "Barlow", sans-serif;
    color: rgb(255, 255, 255);
    font-size: 50px;
    margin-bottom: 1rem;
    width: 100%;
    @media screen and (max-width: 1300px) {
      font-size: 24px;
      margin-bottom: 20px;
    }
  }

  p {
    font-family: "Barlow", sans-serif;
    margin-top: 30px;
    font-size: 22px;
    font-weight: 300;
    color: white;
    @media screen and (max-width: 1300px) {
      font-size: 18px;
      margin-bottom: 17px;
    }
  }

  img {
    max-width: 100%;
    border-radius: 20px;
    @media screen and (max-width: 1300px) {
      margin-bottom: 20px;
    }
  }

  button {
    margin-block: 50px;
    align-items: center;
    background-color: #fcfcfd;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #000000;
    cursor: pointer;
    display: inline-flex;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 1.2vw;
    width: 70%;
    @media screen and (max-width: 1300px) {
      font-size: 14px;
      margin-block: 40px;
    }
  }

  button:focus {
    box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  }

  button:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  button:active {
    box-shadow: #d6d6e7 0 3px 7px inset;
    transform: translateY(2px);
  }

  .AboutInfo-content {
    display: flex;
    min-height: 38vw;

    .AboutInfo-content-left {
      width: 50%;
      text-align: center;
      padding-inline: 4rem;
      padding-top: 1rem;
      background: radial-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0));
    }
    .AboutInfo-content-right {
      text-align: center;
      padding-top: 1rem;
      padding-inline: 4rem;
      width: 50%;
    }

    @media screen and (max-width: 1300px) {
      flex-direction: column;

      .AboutInfo-content-left {
        width: 100%;
      }

      .AboutInfo-content-right {
        width: 100%;
      }
    }
  }
}
