.banner-container {
  display: flex;
  flex-direction: row;
  padding-top: 5.8rem;
  padding-bottom: 1rem;
  overflow: hidden;

  .banner-column-left {
    width: 66%;
    img {
      width: 100%;
      vertical-align: bottom;
      border-radius: 0px 0px 80px 0px;
      filter: drop-shadow(10px 10px 4px rgba(0, 0, 0, 0.5));
    }
  }
  .banner-column-right {
    text-align: center;
    padding-top: 65px;
    padding-inline: 4vw;
    color: white;
    width: 50%;
    h1 {
      font-family: "Barlow", sans-serif;
      text-transform: uppercase;
      font-size: 2.5vw;
      font-weight: 800;
      margin-bottom: 40px;
    }

    h2 {
      font-family: "Barlow", sans-serif;
      font-size: 1.5vw;
      font-weight: 300;
      margin-bottom: 40px;
    }

    p {
      font-family: "Barlow", sans-serif;
      font-size: 1.7vw;
      font-weight: 500;
      margin-bottom: 2.5vw;
    }

    button {
      align-items: center;
      background-color: #fcfcfd;
      border-radius: 4px;
      border-width: 0;
      box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
      box-sizing: border-box;
      color: #000000;
      cursor: pointer;
      display: inline-flex;
      font-family: "Barlow", sans-serif;
      font-weight: 700;
      height: 48px;
      justify-content: center;
      line-height: 1;
      list-style: none;
      overflow: hidden;
      padding-left: 16px;
      padding-right: 16px;
      position: relative;
      text-align: left;
      text-decoration: none;
      transition: box-shadow 0.15s, transform 0.15s;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      white-space: nowrap;
      will-change: box-shadow, transform;
      font-size: 1.2vw;
    }

    button:focus {
      box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    }

    button:hover {
      box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
        rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
      transform: translateY(-2px);
    }

    button:active {
      box-shadow: #d6d6e7 0 3px 7px inset;
      transform: translateY(2px);
    }
  }

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    padding-top: 2rem;
    width: 100%;
    .banner-column-left {
      width: 100%;
    }

    .banner-column-right {
      width: 100%;
      h1 {
        font-size: 6vw;
        margin-bottom: 4vw;
      }
      h2 {
        font-size: 3vw;
        margin-bottom: 4vw;
      }
      p {
        font-size: 3.5vw;
        margin-bottom: 4vw;
      }
      button {
        font-size: 2vw;
        margin-bottom: 4vw;
      }
    }
  }

  @media screen and (max-width: 600px) {
    .banner-column-right {
      padding-top: 5vw;
      width: 100%;
      padding-inline: 20px;
      h1 {
        font-size: 6vw;
        margin-bottom: 4vw;
      }
      h2 {
        font-size: 5vw;
        margin-bottom: 4vw;
      }
      p {
        font-size: 4vw;
        margin-bottom: 9vw;
      }
      button {
        font-size: 4vw;
        margin-bottom: 5vw;
      }
    }
  }
}

$total-arrows: 3;
$arrow-line-length: 50px;
$arrow-line-width: 4px;

// arrow animtion + choose direction
@mixin arrow-transitions($rot: 0deg) {
  transform: translate(-50%, -50%) rotateZ($rot);
}

.arrow-container-main {
  margin-top: 20px;
  transform: translateX(500%);
  width: 10vw;
}
// arrows
.arrow-container {
  display: block;
  width: 120px;
  height: 120px;
  position: relative;
  text-align: center;
  padding: 30px;
  transform: translateY(-90px);
  @include arrow-transitions(0deg);
  &:hover {
    cursor: pointer;
    .arrow {
      top: 50%;
      &:before {
        @include arrow-transitions(-30deg);
      }
      &:after {
        @include arrow-transitions(30deg);
      }
    }
  }
}
.arrow {
  margin: 30px;
  position: absolute;
  transition: all 0.4s ease;
  &:before,
  &:after {
    transition: all 0.4s ease;
    content: "";
    display: block;
    position: absolute;
    transform-origin: bottom right;
    background: #fff;
    width: $arrow-line-width;
    height: $arrow-line-length;
    border-radius: 10px;
    transform: translate(-50%, -50%) rotateZ(-45deg);
  }
  &:after {
    transform-origin: bottom left;
    transform: translate(-50%, -50%) rotateZ(45deg);
  }
  @for $i from 1 through $total-arrows {
    &:nth-child(#{$i}) {
      opacity: 0.3 * $i;
      top: 15 + calc(100% * $i/5);
    }
  }
}
