.app__navbar {
  width: 100%;
  min-height: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0);
  padding: 0.5rem 2rem;
  backdrop-filter: blur(4.5px);
  -webkit-backdrop-filter: blur(4px);
  border: 1px solid rdba(255, 255, 255, 0.18);
  position: fixed;
  z-index: 20;
  background-color: black;
  animation: fadeInDown;
  animation-duration: 1s;

  .app__navbar-namelogo {
    justify-content: flex-start;
    align-items: center;
    animation: fadeInDown;
    img {
      cursor: pointer;
      margin-left: 100px;
      width: 180px;
    }
    @media screen and (max-width: 1300px) {
      img {
        margin-left: 10px;
        width: 150px;
      }
    }
  }
}

.app__navbar-links {
  flex: 1;
  display: flex;
  justify-content: right;
  align-items: center;
  list-style: none;

  li {
    color: white;
    margin: 0 1.5rem;
    cursor: pointer;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    a {
      color: white;
      text-decoration: none;
      flex-direction: column;
      text-shadow: 0 0 10px rgb(0, 0, 0);
      text-transform: uppercase;
      font-weight: 500;
    }

    &:hover {
      text-decoration: underline;
      text-underline-offset: 0.3em;
    }
  }

  @media screen and (max-width: 900px) {
    display: none;
  }
}

.app__navbar-menu {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(123, 66, 255);

  svg {
    width: 70%;
    height: 70%;
    color: white;
    background-color: transparent;
  }

  div {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 11;

    padding: 1rem;

    width: 80%;
    height: 100vh;

    display: flex;
    justify-content: flex-end;

    flex-direction: column;

    background-color: rgba(0, 0, 0, 0.951);
    background-size: cover;
    background-repeat: repeat;

    box-shadow: 0 0 20px rgba(168, 168, 168, 0.15);

    svg {
      width: 35px;
      height: 35px;
      color: white;
      margin: 0.5rem 1rem;
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      height: 100%;
      width: 100%;
      background-color: transparent;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;

      li {
        margin: 1rem;

        button {
          background-color: black;
          color: white;
          text-decoration: none;
          font-size: 1rem;
          text-transform: uppercase;
          font-weight: 500;
          border: 0px;
          transition: all 0.3x ease-in-out;
          &:active {
            div {
              background: "white";
            }
          }

          &:hover {
            div {
              background: "white";
            }
          }
        }
      }
    }

    @media screen and (min-width: 900px) {
      display: none;
    }
  }

  @media screen and (min-width: 900px) {
    display: none;
  }
}

.socialIcon-container {
  display: flex;
  justify-content: right;
  align-items: right;

  @media screen and (max-width: 1500px) {
    display: none;
  }
}

.socialIcon {
  transition: 0.3s ease;
  color: var(--secondary-color);
  border-radius: 50px;
  padding: 12px;

  &:nth-of-type(1) {
    &:hover {
      background-color: red;
      transform: scale(0.9);
      cursor: pointer;
    }
  }

  &:nth-of-type(2) {
    &:hover {
      background: linear-gradient(
        45deg,
        #f09433 0%,
        #e6683c 25%,
        #dc2743 50%,
        #cc2366 75%,
        #bc1888 100%
      );
      transform: scale(0.9);
      cursor: pointer;
    }
  }
}

.fade {
  animation: 0.4s fadeIn 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.container {
  position: relative;
  display: inline-block;
  padding: 0 30px;
  cursor: pointer;
}

.text {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 800;
  position: relative;
  color: white;

  @media screen and (max-width: 1110px) { 
    font-size: 15px;
  }

  @media screen and (max-width: 1050px) { 
    font-size: 12px;
  }
}

.text::before {
  content: attr(data-text);
  position: absolute;
  color: black;
  width: 0;
  overflow: hidden;
  transition: 0.3s;
}

.container::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background: white;
  right: 0;
  top: 0;
  transition: 0.25s;
}

.container:hover .text::before,
.container:hover::before {
  width: 100%;
}
