.app__contact-container {
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1300px) {
    flex-direction: column;
    font-size: 40px;
  }

  .app__contact-text {
    text-align: center;
    h1 {
      color: rgb(255, 255, 255);
      font-size: 70px;
      margin-bottom: 1.5rem;
      width: 100%;
      letter-spacing: 3px;
      @media screen and (max-width: 1300px) {
        font-size: 40px;
      }
    }

    a {
      font-size: 22px;
      color: white;
      font-weight: 500;
    }
  }

  button {
    margin-top: 3rem;
    align-items: center;
    appearance: none;
    background-color: #fcfcfd;
    border-radius: 4px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    box-sizing: border-box;
    color: #000000;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-flex;
    font-family: "Barlow", sans-serif;
    font-weight: 700;
    height: 48px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    overflow: hidden;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow 0.15s, transform 0.15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow, transform;
    font-size: 20px;
    min-width: 780px;
    margin-bottom: 100px;
    @media screen and (max-width: 1300px) {
      min-width: 360px;
    }
  }

  button:focus {
    box-shadow: #d6d6e7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
  }

  button:hover {
    box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px,
      rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #d6d6e7 0 -3px 0 inset;
    transform: translateY(-2px);
  }

  button:active {
    box-shadow: #d6d6e7 0 3px 7px inset;
    transform: translateY(2px);
  }

  form {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1300px) {
      flex-direction: column;
    }

    .app__contact-form-input-row {
      display: flex;
      flex-direction: row;
      margin-top: 3rem;
      align-content: center;
      justify-content: center;

      .input {
        font-size: calc(0.8rem + 0.4vw);
        position: relative;

        &__label {
          position: absolute;
          left: 0;
          top: 0;
          padding: calc(1rem * 0.75) calc(1rem * 0.5);
          margin: calc(1rem * 0.75 + 3px) calc(1rem * 0.5);
          background: pink;
          white-space: nowrap;
          transform: translate(0, 0);
          transform-origin: 0 0;
          background: transparent;
          transition: transform 120ms ease-in;
          font-weight: bold;
          line-height: 1.2;
        }

        &__field {
          @media screen and (max-width: 1300px) {
            min-width: 360px;
          }
          min-width: 780px;
          box-sizing: border-box;
          border: 3px solid white;
          padding: calc(1rem * 1.5) 1rem;
          color: black;
          background: white;
          border-radius: 4px;
          &:focus,
          &:not(:placeholder-shown) {
            & + .input__label {
              -webkit-transform: translate3d(0rem, -135%, 0rem) scale(120%);
              -webkit-transform: -webkit-translate3d(0rem, -135%, 0rem)
                scale(120%);
              -ms-transform: translate3d(0rem, -135%) scale(120%, 0rem);
              color: white;
            }
          }
        }
      }
    }
  }

  .textarea {
    font-size: calc(0.8rem + 0.4vw);
    position: relative;

    &__label {
      position: absolute;
      left: 0;
      top: 0;
      padding: calc(1rem * 0.75) calc(1rem * 0.5);
      margin: calc(1rem * 0.75 + 3px) calc(1rem * 0.5);
      background: rgb(255, 255, 255);
      white-space: nowrap;
      transform: translate(0, 0);
      transform-origin: 0 0;
      background: transparent;
      transition: transform 120ms ease-in;
      font-weight: bold;
      line-height: 1.2;
    }

    &__field {
      @media screen and (max-width: 1300px) {
        min-width: 360px;
      }
      min-width: 780px;
      box-sizing: border-box;
      display: block;
      border: 3px solid white;
      padding: calc(1rem * 1.5) 1rem;
      color: black;
      background: white;
      border-radius: 4px;
      min-height: 120px;
      &:focus,
      &:not(:placeholder-shown) {
        & + .textarea__label {
          -webkit-transform: translate3d(0rem, -135%, 0rem) scale(120%);
          -webkit-transform: -webkit-translate3d(0rem, -135%, 0) scale(120%);
          -ms-transform: translate3d(0rem, -135%, 0) scale(120%);
          color: white;
        }
      }
    }
  }
}
